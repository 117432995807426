import { buildPeriods, buildPeriodsFor, periodStartsAtFor } from "../../../../common/build-periods"
import { GameWeightTypeEnum, PeriodLengthEnum, SeasonEnumType, SportTypesEnum, SubsectionEnum } from "../../../../common/enums"
import { toDbDate } from "../../../../common/misc-utils"
import { cannotSupportGameType, PoolSettingAttributes, SeasonAttributes, SegmentAttributes } from "../../../../common/pool-settings"
import { sortByOrder } from "../../../../common/sorters"
import { getParam } from "../../../utils/url-utils"
import {
  // ForgotPickFallbackTypes,
  GamesPerPeriodTypes,
  MaxGamesPerPlayoffPeriod,
  OneDay,
  PicksDeadlineTypes,
  SportTypes,
  SpreadTypes,
  TiebreakerTypes,
} from "./constants"
import { GameInstanceFormProps, RoundBonusTypeEnum, Segment, TGameType, TPeriodLength, TSeasonType } from "./types.d"

export { buildPeriodsFor, toDbDate }

export const actionParams = (action: "EDIT" | "ROLLOVER" | "COPY", mapping: any) => {
  const configEncoded = encodeURIComponent(encodeURIComponent(JSON.stringify(mapping)))
  return `action=${action}&config=${configEncoded}`
}

export function clearMpid(seasons) {
  if (seasons && seasons.length) {
    seasons.forEach((s) => delete s.masterProductId)
  }
}

const getConfigFrom = (search: string) => {
  const configString = getParam("config", search)
  const action = getParam("action", search)
  let config: any
  if (configString) {
    try {
      config = JSON.parse(decodeURIComponent(configString.toString()))
    } catch (err) {
      console.error(err)
      // TODO qac: log error cuz we wanna know when we suck
    }
  }
  return {
    config,
    action,
  }
}

export const decodeActionParams = (search: string, form: GameInstanceFormProps, gameInstanceId?: string) => {
  // console.debug(`action: ${action}, configString: ${decodeURIComponent(configString)}`);
  const config = getConfigFrom(search).config
  if (config) {
    const { gameInstances, seasons, segments } = config
    // console.log('config')
    // console.dir(config)
    // console.dir(form)
    Object.assign(form, gameInstances[0])
    const Segments = [] as Segment[]
    segments.forEach((segment: any) => {
      // NOTE qac: there is only 1 season ever
      const season = seasons[0] // .find((s) => s.id == segment.seasonId);
      const poolSettings = typeof segment.poolSettings == "string" ? JSON.parse(segment.poolSettings) : segment.poolSettings
      const formSegment = {} as Segment
      const periods = config.periods.filter((per) => per.segmentId == segment.id).sort(sortByOrder)
      const setKey = (key) => {
        // console.log(`key: ${key}`)
        if (season.hasOwnProperty(key)) {
          formSegment[key] = season[key]
        }
        if (segment.hasOwnProperty(key)) {
          formSegment[key] = segment[key]
        }
        if (poolSettings.hasOwnProperty(key)) {
          formSegment[key] = poolSettings[key]
        }
      }
      SegmentAttributes.forEach(setKey)
      SeasonAttributes.forEach(setKey)
      PoolSettingAttributes.forEach(setKey)
      // TODO qac: see above todo on sportType vs sportTypes
      // delete formSegment.sportType;
      formSegment.sportTypes = segment.sportType.split("|")
      formSegment.seasonType = season.season
      // PeriodLengths
      if (!isValidValue("periodLength", formSegment.periodLength, formSegment, form.poolType)) {
        formSegment.periodLength = getPeriodLengthFor(formSegment.seasonType || SeasonEnumType.pre, formSegment.gameType, formSegment.sportTypes[0])
      }
      if (periods.length) {
        // console.log('here')
        // formSegment.startsAt = periods[0].startsAt.replace(/\.\d\d\dZ/, "");
        // formSegment.endsAt = periods[periods.length - 1].endsAt.replace(/\.\d\d\dZ/, "");
        // console.dir(formSegment)
        formSegment.startsAt = toDbDate(periods[0].startsAt)
        formSegment.endsAt = toDbDate(periods[periods.length - 1].endsAt)
        // console.dir(formSegment)
        formSegment.rolloverAt = toDbDate(new Date())
      }
      Segments.push(formSegment)
    })
    form.associations.Segment = Segments
  }
  return config
}

export const clearRowDbAttrs = (row: any) => {
  if (row.createdAt) {
    delete row.createdAt
  }
  if (row.updatedAt) {
    delete row.updatedAt
  }
  if (row.id) {
    delete row.id
  }
  return row
}

export const cleanInvalidValues = (modelSegment, formSegment, poolType: string) => {
  // clean up keys that should not be in there (but persist them in the form!)
  Object.keys(modelSegment).forEach((key) => {
    // console.debug(`testing '${key}'`)
    if (cannotHaveAttribute(key, formSegment, poolType)) {
      // console.debug(`deleting invalid key: '${key}'`)
      delete modelSegment[key]
    }
  })
}

export const hax = (form: GameInstanceFormProps, gameInstancesData: any) => {
  const GameInstance = Object.assign({ state: "ACTIVE" }, form) as any
  const Segments = GameInstance.associations.Segment
  GameInstance.associations = {
    Season: [],
  }
  const nowAt = new Date()
  Segments.forEach((segment) => {
    const poolSettings = {}
    if (!segment.subsection) {
      delete segment.subsection
    }
    // segment.subsection = segment.subsection || ""
    const yearOfSeason = segment.startsAt ? new Date(segment.startsAt) : nowAt
    const year = yearOfSeason.getFullYear()
    // console.debug(`hax: ${segment.startsAt} ${segment.endsAt}  ${segment.sportTypes}`);
    const canHavePeriods = !!(segment.startsAt && segment.endsAt && segment.sportTypes.length)
    PoolSettingAttributes.forEach((key) => (poolSettings[key] = segment[key]))
    // clean up keys that should not be in there (but persist them in the form!)
    cleanInvalidValues(poolSettings, segment, GameInstance.poolType)
    const periods = canHavePeriods
      ? buildPeriods(
          segment.gameType,
          segment.sportTypes,
          segment.periodLength,
          segment.seasonType,
          year,
          year,
          segment.subsection,
          [],
          gameInstancesData.byWeek,
          segment.hasGameDataOnly,
          form.poolType === "CHALLENGE",
        )
      : []
    const newSegment = {
      poolSettings,
      associations: {
        Period: periods,
      },
    } as any
    SegmentAttributes.forEach((key) => (newSegment[key] = segment[key]))
    cleanInvalidValues(newSegment, segment, GameInstance.poolType)
    // TODO qac: ugh we cant really have an array in the db...
    newSegment.sportType = segment.sportTypes.join("|")
    const season = {
      year,
      season: segment.seasonType,
      challengePoolSlug: segment.challengePoolSlug,
      masterProductId: segment.masterProductId,
      productAbbrev: segment.productAbbrev,
      associations: {
        Segment: [newSegment],
      },
    }
    GameInstance.associations.Season.push(season)
  })
  if (typeof window !== "undefined") {
    const params = getConfigFrom(window.location.search)
    if (params.config && params.config.GameInstance) {
      GameInstance.id = params.config.GameInstance.id
      if (params.action === "EDIT") {
        GameInstance.associations.Season.forEach((season, i) => {
          if (params.config.GameInstance.associations.Season.length < i) {
            const paramSeason = params.config.GameInstance.associations.Season[i]
            season.id = paramSeason.id
            season.associations.Segment.forEach((segment, j) => {
              if (paramSeason.associations.Segment.length < i) {
                // const paramSegment = paramSeason.associations.Segment[i];
                segment.id = paramSeason.id
              }
            })
          }
        })
      }
    }
  }
  return GameInstance
}

export const isDisabledValue = (attrName: string, value: any, segment: Segment): boolean => {
  // SportTypes
  if (/^sportTypes$/.test(attrName)) {
    if (segment.sportTypes.length > 0 && !segment.sportTypes.includes(value)) {
      return true
    }
  }
  return false
}

export const isValidValue = (attrName: string, value: any, segment: Segment, poolType?: string): boolean => {
  const seasonType = segment.seasonType || ""
  const periodLength = segment.periodLength || ""
  const sports = segment.sportTypes.join("-")
  const gameType = segment.gameType
  // seasonTypes
  if (/^seasonType$/.test(attrName)) {
    if (segment.sportTypes.includes(SportTypesEnum.NCAAF) && /pre/.test(value)) {
      return false
    }
    const isNCAAB_W = segment.sportTypes.includes(SportTypesEnum.NCAAB) || segment.sportTypes.includes(SportTypesEnum.NCAAW)
    if (isNCAAB_W && /pre|regular/.test(value)) {
      return false
    }
    if (segment.gameType === "BRACKET" && /pre|regular/.test(value)) {
      return false
    }
  }
  // periodLength
  if (/^periodLength$/.test(attrName)) {
    // bracket is only post
    // console.debug(`${value} - ${/Bracket/.test(gameType)} && ${/NFL|NCAAB/.test(sports)} && ${/post/.test(seasonType)} `)
    if (/FULL/.test(value) && /BRACKET/.test(gameType) && /NFL|NCAAB|NCAAF/.test(sports) && /post/.test(seasonType)) {
      return true
    }
    // NFL pre + reg
    if (/WEEKLY|DAILY|MONTHLY/.test(value) && /PICKEM/.test(gameType) && /NFL|NCAAB|NCAAF/.test(sports) && /pre|regular/.test(seasonType)) {
      return true
    }
    if (/ROUND/.test(value) && /PICKEM/.test(gameType) && /NFL|NCAAB/.test(sports) && /post/.test(seasonType)) {
      return true
    }
    return false
  }
  // tiebreakers
  if (/^(main|secondary)Tiebreaker$/.test(attrName)) {
    // disable round totals unless rounds are involved
    if (/ROUND_TOTALS/.test(value) && !/ROUND/.test(periodLength)) {
      return false
    }
    return true
  }
  // challengePoolSlug
  if (/^challengePoolSlug$/.test(attrName) && poolType) {
    // remove challengePoolSlug unless its a challenge game type
    if (poolType !== "CHALLENGE") {
      return false
    }
    return true
  }
  return true
}
export const cannotHaveAttribute = (attrName: string, segment: Segment, poolType?: string): boolean => {
  // challengePoolSlug
  if (/^challengePoolSlug$/.test(attrName) && poolType) {
    const canHave = poolType === "CHALLENGE"
    return !canHave
  }
  // spreadType
  if (/^spreadType$/.test(attrName)) {
    return !segment.seasonType || cannotSupportGameType(segment.gameType, SpreadTypes)
  }
  // maxEntriesPerUser
  if (/^maxEntriesPerUser$/.test(attrName)) {
    return segment.gameType != "BRACKET"
  }
  // picksDeadlineType
  if (/^picksDeadlineType$/.test(attrName)) {
    return !segment.seasonType || segment.gameType === "BRACKET" || cannotSupportGameType(segment.gameType, PicksDeadlineTypes)
  }
  // mainTiebreaker|secondaryTiebreaker
  if (/^(main|secondary)Tiebreaker$/.test(attrName)) {
    return cannotSupportGameType(segment.gameType, TiebreakerTypes)
  }
  // forgotPickFallback
  // if (/^forgotPickFallback$/.test(attrName)) {
  //   const canHave = cannotSupportGameType(segment.gameType, ForgotPickFallbackTypes);
  //   return !canHave;
  // }
  // gameWeightType
  if (/^gameWeightType$/.test(attrName)) {
    return segment.spreadType === "CUSTOM_SPREAD" || segment.gameType === "BRACKET"
  }
  // gameWeights: [x,x,x]
  if (/^gameWeights$/.test(attrName)) {
    const canHave =
      segment.gameWeightType === "CUSTOM" &&
      segment.sportTypes.length === 1 &&
      Object.keys(MaxGamesPerPlayoffPeriod)
        .map((sportType) => sportType === segment.sportTypes[0])
        .includes(true)
    return !canHave
  }
  // gameWeightTotalPointsPerMatchup
  // if (/^gameWeightTotalPointsPerMatchup$/.test(attrName)) {
  //   const canHave = segment.gameWeightType === "VEGAS";
  //   return !canHave;
  // }
  // roundBonusType
  if (/^roundBonusType$/.test(attrName)) {
    return !(segment.gameType === "BRACKET" || segment.periodLength === "ROUND")
  }
  // roundBonuses
  if (/^roundBonuses$/.test(attrName)) {
    // return false
    return !(segment.roundBonusType === "STANDARD")
  }
  // gameWeightTotalPointsPerMatchup
  // if (/^roundBonusTotalPointsPerMatchup$/.test(attrName)) {
  //   const canHave = segment.roundBonusType === "VEGAS";
  //   return !canHave;
  // }
  // gamesPerPeriod
  if (/^gamesPerPeriod$/.test(attrName)) {
    return cannotSupportGameType(segment.gameType, GamesPerPeriodTypes)
  }
  // maxPicksPerPeriodCount
  if (/^maxPicksPerPeriodCount$/.test(attrName)) {
    return cannotSupportGameType(segment.gameType, GamesPerPeriodTypes)
  }
  // bracketMarginOfVictoryBonus
  // if (/^bracketMarginOfVictoryBonus$/.test(attrName)) {
  //   return segment.gameType != "BRACKET";
  // }
  // console.debug(`missed: '${attrName}'`)
  return false
}

export const sportRenderValue = (selected) =>
  typeof selected === "object" && selected.map((sel) => (SportTypes.find(({ value }) => value === sel) || { label: null }).label).join(", ")

const MatchNumber = /^-?\d+\.?\d*$/
export const castValue = (value: string) => {
  if (typeof value === "string" && MatchNumber.test(value)) {
    return parseFloat(value)
  }
  return value
}

export const handleFormChange = (
  newValue: any,
  isChecked: boolean,
  form: GameInstanceFormProps,
  gameInstancesData: any,
  attribute: string,
  subResourceNameOrValue?: string,
  index?: number,
  nestedKey?: string,
) => {
  // console.log(`handleFormChange: ${attribute}, ${subResourceNameOrValue} (${subResourceNameOrValue === "seasonType"}), ${index}, ${nestedKey}) (newValue: ${newValue} (${typeof(newValue)})`);
  // disabled / clearing logic:
  // seasonType should toggle period options:
  if (subResourceNameOrValue === "seasonType") {
    const formSegment = form.associations[attribute][index || 0]
    // console.debug(`seasonType changing: ${newValue} -> ${formSegment.seasonType}`)
    if (newValue != formSegment.seasonType) {
      defaultPeriodValues(newValue as TSeasonType, formSegment.gameType, formSegment, gameInstancesData)
    }
  }
  // tournamentIds should toggle period options:
  if (subResourceNameOrValue === "roundBonusType") {
    const formSegment = form.associations[attribute][index || 0]
    // console.debug(`changing: ${newValue} -> ${formSegment.seasonType}`);
    if (newValue != formSegment.roundBonusType) {
      const submittable = { GameInstance: hax(form, gameInstancesData) }
      const periods = getPeriodsFor(submittable, index || 0)
      formSegment.roundBonuses = getRoundBonusesFor(newValue, periods, formSegment.subsection)
    }
  }
  // tournamentIds should toggle period options:
  // if (subResourceNameOrValue === "tournamentIds") {
  // const formSegment = form.associations[attribute][index || 0];
  // console.debug(`changing: ${newValue} -> ${formSegment.seasonType}`);
  // if (newValue != formSegment.tournamentIds) {
  //   defaultPeriodValues(formSegment.seasonType, formSegment.gameType, formSegment, gameInstancesData);
  // }
  // }
  // gameType should toggle period options:
  if (subResourceNameOrValue === "gameType") {
    const formSegment = form.associations[attribute][index || 0]
    // console.debug(`gameType changing: ${newValue} -> ${formSegment.seasonType}`)
    const formerValue = formSegment.gameType
    if (newValue != formerValue) {
      const newSeasonType = newValue === "BRACKET" ? "post" : formSegment.seasonType || "regular"
      defaultPeriodValues(newSeasonType, newValue, formSegment, gameInstancesData)
    }
  }
  // can only have x1 sport for now:
  if (subResourceNameOrValue === "sportTypes") {
    const formSegment = form.associations[attribute][index || 0]
    const formerValue = formSegment.sportTypes
    // console.debug(`sportTypes changing: ${newValue} -> ${formerValue}`)
    if (newValue && formerValue && /\,/.test(newValue.toString())) {
      return false
    }
    if (newValue != formerValue) {
      defaultPeriodValues(formSegment.seasonType, formSegment.gameType, formSegment, gameInstancesData)
    }
  }
  if (subResourceNameOrValue && typeof index !== "undefined") {
    if (nestedKey) {
      // deep nested structure
      const former = form.associations[attribute][index]
      const newNestedValue = Object.assign({}, former[subResourceNameOrValue] || {})
      newNestedValue[nestedKey] = newValue
      Object.assign(former, { [subResourceNameOrValue]: newNestedValue })
    } else {
      // simple structure (or array select)
      const former = form.associations[attribute][index]
      Object.assign(former, { [subResourceNameOrValue]: newValue })
    }
  } else if (subResourceNameOrValue) {
    const indexOfValue = form[attribute].indexOf(subResourceNameOrValue)
    if (isChecked && indexOfValue < 0) {
      form[attribute] = form[attribute].concat([subResourceNameOrValue])
    }
    if (!isChecked && indexOfValue >= 0) {
      form[attribute] = form[attribute].splice(indexOfValue, 1)
    }
  } else {
    if (form[attribute] == newValue) {
      return false
    }
    form[attribute] = newValue
  }
  return true
}

export function getPeriodLengthFor(seasonType: TSeasonType, gameType: TGameType, sportType: SportTypesEnum): TPeriodLength {
  if (gameType === "BRACKET") {
    // bracket must be full
    return PeriodLengthEnum.FULL
  } else if (gameType === "PICKEM" && [SportTypesEnum.NFL, SportTypesEnum.NCAAF].includes(sportType)) {
    if (["post"].includes(seasonType)) {
      return PeriodLengthEnum.ROUND
    } else {
      return PeriodLengthEnum.WEEKLY
    }
  }
  return PeriodLengthEnum.DAILY
}
export function getDefaultRoundBonusTypeFor(seasonType: TSeasonType, gameType: TGameType, sportType: SportTypesEnum) {
  if (gameType === "BRACKET") {
    // bracket must be full
    return RoundBonusTypeEnum.STANDARD
  } else if (gameType === "PICKEM" && ["post"].includes(seasonType)) {
    return RoundBonusTypeEnum.STANDARD
  } else if (gameType === "PICKEM" && ["pre", "regular"].includes(seasonType)) {
    return RoundBonusTypeEnum.NONE
  }
  return RoundBonusTypeEnum.NONE
}
export function getDefaultGameWeightTypeFor(seasonType: TSeasonType, gameType: TGameType, sportType: SportTypesEnum) {
  if (gameType === "BRACKET") {
    // bracket must be full
    return GameWeightTypeEnum.ROUND
  }
  // else if ([SportTypesEnum.NFL].includes(sportType) && gameType === "PICKEM" && ["post"].includes(seasonType)) {
  //   return GameWeightTypeEnum.CONFIDENCE
  // }
  else if (gameType === "PICKEM" && ["pre", "regular"].includes(seasonType)) {
    return GameWeightTypeEnum.NONE
  }
  return GameWeightTypeEnum.NONE
}
export function getRoundBonusesFor(roundBonusType: RoundBonusTypeEnum, periods: any[], subsection?: SubsectionEnum): number[] {
  if (roundBonusType === RoundBonusTypeEnum.STANDARD) {
    return periods.map((_, idx) => idx + 1)
  } else {
    return []
  }
}

export const defaultPeriodValues = (seasonType: TSeasonType, gameType: TGameType, formSegment: Segment, gameInstancesData: any) => {
  delete formSegment.periodLength
  const currentYear = new Date().getFullYear()
  formSegment.startsAt = `${currentYear}-08-01T12:00`
  formSegment.endsAt = `${currentYear}-09-11T12:00`
  formSegment.periodLength = getPeriodLengthFor(seasonType, gameType, formSegment.sportTypes[0])
  if (formSegment.sportTypes.includes(SportTypesEnum.NFL)) {
    // console.dir(gameInstancesData)
    const realSeasonData = gameInstancesData.sql.find((d) => formSegment.sportTypes.includes(d.sportType) && seasonType === d.seasonType)
    if (realSeasonData && realSeasonData["MIN(startsAt)"] && realSeasonData["MAX(startsAt)"]) {
      formSegment.startsAt = toDbDate(periodStartsAtFor({ startsAt: realSeasonData["MIN(startsAt)"], sportType: realSeasonData.sportType }))
      formSegment.endsAt = toDbDate(periodStartsAtFor({ startsAt: realSeasonData["MAX(startsAt)"], sportType: realSeasonData.sportType }))
    } else if (seasonType === "pre") {
      formSegment.startsAt = `${currentYear}-08-01T12:00`
      formSegment.endsAt = `${currentYear}-09-11T12:00`
    } else if (seasonType === "regular") {
      formSegment.startsAt = `${currentYear}-09-11T12:00`
      formSegment.endsAt = `${currentYear + 1}-01-05T12:00`
    } else if (seasonType === "post") {
      formSegment.startsAt = `${currentYear + 1}-01-05T12:00`
      formSegment.endsAt = `${currentYear + 1}-02-15T12:00`
    }
  }
  // NCAAF trumps NFL (in case shared)
  if (formSegment.sportTypes.includes(SportTypesEnum.NCAAF)) {
    const realSeasonData = gameInstancesData.byWeek.filter(
      (d) => d.sportType === SportTypesEnum.NCAAF && seasonType === d.seasonType && currentYear === d.year,
    )
    if (realSeasonData.length) {
      const startDate = periodStartsAtFor({ startsAt: realSeasonData[0]["MIN(startsAt)"], sportType: SportTypesEnum.NCAAF })
      const weekCount = realSeasonData.length
      const change = OneDay * 7 * weekCount
      formSegment.startsAt = toDbDate(startDate)
      formSegment.endsAt = toDbDate(new Date(startDate.getTime() + change))
      // formSegment.startsAt = toDbDate(periodStartsAtFor({ startsAt: realSeasonData["MIN(startsAt)"], sportType: realSeasonData.sportType }));
      // formSegment.endsAt = toDbDate(periodStartsAtFor({ startsAt: realSeasonData["MAX(startsAt)"], sportType: realSeasonData.sportType }));
      // console.log("1");
      // console.dir(realSeasonData);
    } else if (seasonType === "regular") {
      formSegment.startsAt = `${currentYear}-08-24T11:00`
      formSegment.endsAt = `${currentYear}-11-20T11:00`
    } else if (seasonType === "post") {
      formSegment.startsAt = `${currentYear}-11-20T11:00`
      formSegment.endsAt = `${currentYear + 1}-02-15T11:00`
    }
  }
  if (formSegment.sportTypes.includes(SportTypesEnum.NCAAB) || formSegment.sportTypes.includes(SportTypesEnum.NCAAW)) {
    // only post currently
    formSegment.startsAt = `${currentYear}-03-19T01:00` // "2019-03-19T01:00";
    formSegment.endsAt = `${currentYear}-04-08T23:00` // "2019-04-08T23:00";
  }
  // console.dir(formSegment)
}

export const getPeriodsFor = (submittable, index) =>
  (submittable.GameInstance &&
    submittable.GameInstance.associations.Season[index] &&
    submittable.GameInstance.associations.Season[index].associations.Segment[index] &&
    submittable.GameInstance.associations.Season[index].associations.Segment[index].associations.Period) ||
  []
